import React from "react"

export default function Home() {
	return (
		<div>
			<h1>Hello world!</h1>
			<a href="/blog">Blog</a>
		</div>
	)
}
